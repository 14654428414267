import React, { useEffect } from "react";
import { useToken } from "../Utility/StoreData";
import { useDispatch } from "react-redux";
import {
  handleStoreUserData,
  handleStoreUserToken,
} from "../Store/Reducers/LoginReducer";
import { getCookie, setCookie } from "../Store/Storage/Cookie";
import instance from "../Services/Axios";
import { getCatchMsg } from "../Utility/GeneralUtils";
import { getAccessPermissions } from "../Services/Services";
import { permissionKey } from "../Utility/Constants";

function InstanceBase() {
  const token = useToken();
  const dispatch = useDispatch();
  const cookieData = getCookie("vt_enterprise_login") ?? null;
  instance.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => {
      if (response.data.status === -1) {
        dispatch(handleStoreUserData(null));
        setCookie("vt_enterprise_login", null, 0);
        localStorage.removeItem(permissionKey);
        window.location.replace("/");
      }
      return response;
    },
    function (error) {
      getCatchMsg(error);
    }
  );

  const handlegetAccessPermissions = () => {
    let formData = new FormData();
    formData.append("token", token);
    formData.append("id", cookieData?.data?.user_id);
    getAccessPermissions(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          localStorage.removeItem(permissionKey);
          const temp = response?.data?.access_permission;
          localStorage.setItem(
            permissionKey,
            response?.data?.access_permission
          );
        }
      })
      .catch((err) => {
        console.log(err, "ERROR FROM GET ACCESS.");
      });
  };

  useEffect(() => {
    if (token && cookieData) {
      handlegetAccessPermissions();
    }
  }, [token, cookieData]);

  useEffect(() => {
    if (token === null) {
      try {
        const cookieUserData = getCookie("vt_enterprise_login");
        if (cookieUserData) {
          dispatch(handleStoreUserToken(cookieUserData?.data?.token));
          dispatch(handleStoreUserData(cookieUserData?.data));
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [token]);
  return <div></div>;
}

export default InstanceBase;
