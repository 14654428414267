import React, { useEffect, useState } from "react";
import PageHeader from "../ManagementLayoutHeader/PageHeader";
import classes from "./Management.module.css";
import { CustomToolTip, GlobalModal, Loader } from "../../Components";
import AddEmployee from "../../Modals/AddEmployee";
import EmployeeChangePassword from "../../Modals/EmployeeChangePassword";
import { deleteEmployee, employeeList } from "../../Services/Services";
import { getCatchMsg, getLocalStorageData } from "../../Utility/GeneralUtils";
import chagepassword_Icon from "../../Assets/Icons/SvgIcons/password_key.svg";
import CustomPagination from "../../Components/CustomPagination";
import NoDataFound from "../../Components/NoDataFound";
import { getTableSNO } from "../../Utility/Constants";
import {
  useEmployeeId,
  useEmployeeType,
  useToken,
} from "../../Utility/StoreData";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../Assets/Icons/SvgIcons/delete.svg";
import toast from "react-hot-toast";
import LogoutConfirmationModal from "../../Modals/LogoutConfirmationModal";
import Access_iocn from "../../Assets/Images/Access_icon.svg";

function EmployeeList() {
  const token = useToken();
  const navigate = useNavigate();
  const userId = useEmployeeId();
  const localData = getLocalStorageData()?.emploees;
  const userType = useEmployeeType();
  const [isShowModal, setIsShowModal] = useState({
    status: false,
    changePasswordStatus: false,
    id: "",
  });
  const [page, setPage] = useState(0);
  const [listOfEmployees, setListOfEmployees] = useState();
  const [loader, setloader] = useState(false);
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    data: "",
  });
  useEffect(() => {
    if (token) {
      handleGetEmployeeList();
    }
  }, [token]);

  const handleGetEmployeeList = (page = 1) => {
    setloader(true);
    let formData = new FormData();
    formData.append("id", userId);
    formData.append("token", token);
    formData.append("user_type", userType);
    formData.append("limit", 10);
    employeeList(page, formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          setPage(parseInt(response?.data?.data?.page) - 1);
          setListOfEmployees(response?.data?.data);
        } else if (response?.data?.status === 0) {
          setListOfEmployees(null);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setloader(false);
      });
  };

  const handleSortByType = (userType) => {
    return userType === 1
      ? "Super admin"
      : userType === 2
      ? "Admin"
      : "Line inspector";
  };

  const handleDeleteUser = () => {
    setloader(true);
    let formData = new FormData();
    const temp = deleteModal?.data;
    formData.append("token", token);
    formData.append("id", temp?.id);
    deleteEmployee(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          const pag = listOfEmployees?.items.length === 1 ? page : page + 1;
          handleGetEmployeeList(pag);
        } else if (response?.data?.status === 0) {
          toast.success(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        handleDeleteModal();
        setloader(false);
      });
  };

  const handleDeleteModal = () => {
    setdeleteModal((prev) => {
      return {
        ...prev,
        modal: false,
        data: "",
      };
    });
  };

  const ActionStatus = () => {
    return localData?.delete || localData?.access || localData?.change_password;
  };
  return (
    <>
      {loader ? <Loader /> : null}
      <PageHeader
        secondBtn={false}
        heading={"Employee List"}
        Btntitle={localData?.add ? "Add Employee" : ""}
        modal={() => {
          setIsShowModal((prev) => {
            return {
              ...prev,
              status: true,
              id: null,
            };
          });
        }}
      />
      <GlobalModal
        title={"Add Employee"}
        isOpen={isShowModal.status}
        onCancel={() => {
          setIsShowModal((prev) => {
            return {
              ...prev,
              status: false,
            };
          });
        }}
      >
        <AddEmployee
          listApiCall={handleGetEmployeeList}
          modalClose={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: false,
              };
            });
          }}
        />
      </GlobalModal>
      <GlobalModal
        CustomWidth={500}
        title={"Change Password"}
        isOpen={isShowModal.changePasswordStatus}
        onCancel={() => {
          setIsShowModal((prev) => {
            return {
              ...prev,
              changePasswordStatus: false,
            };
          });
        }}
      >
        <EmployeeChangePassword
          employeeId={isShowModal?.id}
          modalClose={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                changePasswordStatus: false,
              };
            });
          }}
        />
      </GlobalModal>
      <GlobalModal
        CustomWidth={500}
        isOpen={deleteModal.modal}
        onCancel={handleDeleteModal}
      >
        <LogoutConfirmationModal
          msg={"Are you sure do you want to delete this user?"}
          closeIcon={false}
          positiveButtonText="Delete"
          onClose={handleDeleteModal}
          onPositiveButtonPressed={handleDeleteUser}
          onNegativeButtonPressed={handleDeleteModal}
        />
      </GlobalModal>
      <div className={classes.insepectionCreteria}>
        <div className={`table-responsive ${classes.Dashboard}`}>
          <table className={classes.listOfTable}>
            <thead className={classes.NormalTable}>
              <tr>
                <th>S.No</th>
                <th>Employee Name</th>
                <th>Employee Type</th>
                <th>Email</th>
                {ActionStatus() ? <th>Action</th> : null}
              </tr>
            </thead>
            <tbody>
              {listOfEmployees?.items.length > 0 ? (
                listOfEmployees?.items.map((emp, index) => (
                  <tr key={index}>
                    <td>
                      {getTableSNO(parseInt(listOfEmployees?.page), 10, index)}
                    </td>
                    <td>{emp?.name}</td>
                    <td>{handleSortByType(emp?.user_type)}</td>
                    <td>{emp?.email}</td>
                    {ActionStatus() ? (
                      <td>
                        <div className={classes.icons}>
                          {localData?.change_password ? (
                            <CustomToolTip title={"Change Password"}>
                              <img
                                src={chagepassword_Icon}
                                alt="change password"
                                onClick={() => {
                                  setIsShowModal((prev) => {
                                    return {
                                      ...prev,
                                      changePasswordStatus: true,
                                      id: emp?.id,
                                    };
                                  });
                                }}
                              />
                            </CustomToolTip>
                          ) : null}
                          {localData?.access ? (
                            <CustomToolTip title={"Access"}>
                              <img
                                src={Access_iocn}
                                alt="access_icon"
                                onClick={() =>
                                  navigate("access_group", { state: emp })
                                }
                              />
                            </CustomToolTip>
                          ) : null}
                          {localData?.delete ? (
                            <CustomToolTip title={"Delete"}>
                              <img
                                src={DeleteIcon}
                                alt="delete_icon"
                                style={{
                                  width: 20,
                                  height: 20,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setdeleteModal((prev) => {
                                    return {
                                      ...prev,
                                      data: emp,
                                      modal: true,
                                    };
                                  });
                                }}
                              />
                            </CustomToolTip>
                          ) : null}
                        </div>
                      </td>
                    ) : null}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>
                    <NoDataFound />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {listOfEmployees?.totalPage > 1 && (
        <CustomPagination
          pageCount={listOfEmployees?.totalPage}
          currentpage={page}
          forcePage={page}
          onPageChange={(val) => {
            handleGetEmployeeList(val + 1);
          }}
        />
      )}
    </>
  );
}

export default EmployeeList;
