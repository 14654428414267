import { Navigate, Outlet } from "react-router-dom";
import { getCookie } from "../../Store/Storage/Cookie";
import { permissionKey } from "../../Utility/Constants";
import { getLocalStorageData } from "../../Utility/GeneralUtils";

export const AuthPrivateRoute = () => {
  return getCookie("vt_enterprise_login") === "" ? (
    <Outlet />
  ) : (
    <Navigate to={"/dashboard"} />
  );
};

export const DashboardPrivateRoute = () => {
  return getCookie("vt_enterprise_login") !== "" ? (
    <Outlet />
  ) : (
    <Navigate to={"/"} />
  );
};

export const EmployeePrivateRoute = () => {
  const EmployeePermission = getLocalStorageData();
  return EmployeePermission?.emploees?.menu === 1 ? (
    <Outlet />
  ) : (
    <Navigate to={"/dashboard"} />
  );
};

export const ChangePasswordPrivateRoute = () => {
  const EmployeePermission = getLocalStorageData();
  return EmployeePermission?.change_password?.change_password === 1 ? (
    <Outlet />
  ) : (
    <Navigate to={"/dashboard"} />
  );
};

export const InpectionCriteriaPrivateRoute = () => {
  const EmployeePermission = getLocalStorageData();
  return EmployeePermission?.inspection_criteria?.menu === 1 ? (
    <Outlet />
  ) : (
    <Navigate to={"/dashboard"} />
  );
};

export const ExportPrivateRoute = () => {
  const EmployeePermission = getLocalStorageData();
  return EmployeePermission?.export?.menu === 1 ? (
    <Outlet />
  ) : (
    <Navigate to={"/dashboard"} />
  );
};

export const PrepareinscepectionreportPrivateRoute = () => {
  const EmployeePermission = getLocalStorageData();
  return EmployeePermission?.prepar_inspection_report?.menu === 1 ? (
    <Outlet />
  ) : (
    <Navigate to={"/dashboard"} />
  );
};

export const AccessGroupScreenPrivateRoute = () => {
  const EmployeePermission = getLocalStorageData();
  return EmployeePermission?.emploees?.access === 1 ? (
    <Outlet />
  ) : (
    <Navigate to={"/dashboard"} />
  );
};

export const SavedDataPrivateRoute = () => {
  const EmployeePermission = getLocalStorageData();
  return EmployeePermission?.saved_logs?.menu === 1 ? (
    <Outlet />
  ) : (
    <Navigate to={"/dashboard"} />
  );
};
