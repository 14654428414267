import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AccessList, useToken } from "../../../Utility/StoreData";
import Card from "./Card";
import { CustomButton, Loader, TextInputBox } from "../../../Components";
import PageHeader from "../../ManagementLayoutHeader/PageHeader";
import classes from "./AccessGroup.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getIsChecked } from "../../../Utility/GeneralUtils";
import {
  createAccessGroup,
  getAccessPermissions,
} from "../../../Services/Services";
import toast from "react-hot-toast";
import {
  cleanAllAccessReducer,
  handleStoreAccess,
} from "../../../Store/Reducers/AccessReducers";

export default function AccessGroupScreen() {
  const token = useToken();
  const { state } = useLocation();

  console.log(state, "state");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessList = AccessList();
  const [loader, setloader] = useState(false);

  useEffect(() => {
    if (state?.id && token) {
      handlegetAccessPermissions(state?.id);
    }
  }, [state, token]);

  useEffect(() => {
    return () => {
      dispatch(cleanAllAccessReducer());
    };
  }, []);

  const handlegetAccessPermissions = (id) => {
    setloader(true);
    let formData = new FormData();
    formData.append("token", token);
    formData.append("id", id);
    getAccessPermissions(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          const temp = response?.data?.access_permission;
          if (temp) {
            const data = JSON.parse(temp);
            dispatch(handleStoreAccess(data));
          }
        }
      })
      .catch((err) => {
        console.log(err, "ERROR FROM GET ACCESS.");
      })
      .finally(() => setloader(false));
  };

  //   screen permissions get object type in all screens permissions
  const getPermissionsData = (data) => {
    let temp = {};
    const refData = [...data].forEach(
      (ele) => (temp[ele?.key] = Number(ele?.isVisible))
    );
    return temp;
  };

  //get object type for all screens same function
  const handleGetFinalData = () => {
    let finalData = {};
    const temp = [...accessList]?.forEach(
      (ele) =>
        (finalData[ele?.screenKey] = {
          menu: Number(getIsChecked(ele?.permissions)),
          ...getPermissionsData(ele?.permissions),
        })
    );
    return finalData;
  };

  const handleAddPermissions = () => {
    setloader(true);
    let tempData = {
      token: token,
      id: state?.id,
      access_permission: handleGetFinalData(),
    };
    createAccessGroup(JSON.stringify(tempData))
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          navigate(-1);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setloader(false));
  };

  return (
    <>
      {loader ? <Loader /> : null}
      <PageHeader
        secondBtn={false}
        heading={`Access Group - ${state?.name}`}
        Btntitle={"Back"}
        modal={() => navigate(-1)}
      />
      <div className={classes.insepectionCreteria}>
        <div className="row">
          {[...accessList]?.map((ele, ind) => (
            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4" key={ind}>
              <Card data={ele} key={ind} />
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px 0",
        }}
      >
        <CustomButton
          title="Submit"
          onButtonPress={handleAddPermissions}
          customButtonStyle={{
            backgroundColor: "var(--btnColor)",
            fontSize: "13px",
            fontWeight: "500",
            padding: "10px 15px",
            boxShadow: "0px 1.89921px 3.79843px rgba(0, 0, 0, 0.06)",
          }}
        />
      </div>
    </>
  );
}
