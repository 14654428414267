import React from "react";
import classes from "./AccessGroup.module.css";
import { useDispatch } from "react-redux";
import CustomCheckBox from "../../../Components/CustomCheckBox";
import {
  handleActionSingleAccess,
  handleActionSingleScreen,
} from "../../../Store/Reducers/AccessReducers";
import { getIsChecked } from "../../../Utility/GeneralUtils";

export default function Card({ data }) {
  const dispatch = useDispatch();

  return (
    <div className={classes.singleCardContainer}>
      <div className={classes.cardHeader}>
        <p className={classes.screenName}>
          <b className={classes.underLine}>{data?.screenName}</b>
        </p>
        <CustomCheckBox
          isChecked={getIsChecked(data?.permissions)}
          onChangeCheckBox={() => {
            const temp = {
              ID: data?.id,
              ACTION: getIsChecked(data?.permissions) ? "Clear" : "Add",
            };
            dispatch(handleActionSingleScreen(temp));
          }}
        />
      </div>
      <div className={classes.permissionsContainer}>
        {data?.permissions?.map((ele, ind) => (
          <CustomCheckBox
            key={ind}
            title={ele?.name}
            isChecked={ele?.isVisible}
            onChangeCheckBox={() => {
              dispatch(
                handleActionSingleAccess({ ROWID: data?.id, ID: ele?.id })
              );
            }}
            customInputStyle={{
              width: "18px",
              height: "18px",
            }}
            customTitleStyle={{
              fontSize: "18px",
            }}
          />
        ))}
      </div>
    </div>
  );
}
