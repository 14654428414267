import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Drawer, Tabs } from "antd";
import { Dropdown, Space } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import dash from "../../Assets/Icons/SvgIcons/dash.svg";
import { getCookie, setCookie } from "../../Store/Storage/Cookie";
import { GlobalModal, Loader } from "../../Components";
import export_icon from "../../Assets/Icons/SvgIcons/export_icon.svg";
import prepare_report_icon from "../../../src/Assets/Icons/SvgIcons/prepare_report_icon.svg";
import incoming_icon from "../../Assets/Icons/SvgIcons/change_password.svg";
import menuIcon from "../../Assets/Icons/SvgIcons/menuIcon.svg";
import classes from "./header.module.css";
import LogoutConfirmationModal from "../../Modals/LogoutConfirmationModal";
import CompanyLogo from "../../Assets/Images/VTLogo.svg";
import create_account from "../../Assets/Icons/SvgIcons/create_account.svg";
import change_password from "../../Assets/Icons/SvgIcons/change_password.svg";
import logout from "../../Assets/Icons/SvgIcons/logout.svg";
import { useEmployeeId, useToken, useUserName } from "../../Utility/StoreData";
import { signOut } from "../../Services/Services";
import { handleStoreUserData } from "../../Store/Reducers/LoginReducer";
import dummyIcon from "../../Assets/Icons/SvgIcons/dummy.svg";
import {
  getCatchMsg,
  getLocalStorageData,
  getTrimString,
} from "../../Utility/GeneralUtils";
import saved_logsIcon from "../../Assets/Icons/SvgIcons/saved_logsIcon.svg";
import CloseIcon from "../../Assets/Icons/SvgIcons/close_icon.svg";
import {
  LockOutlined,
  LogoutOutlined,
  CaretDownOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import toast from "react-hot-toast";
import { permissionKey } from "../../Utility/Constants";

export default function Header() {
  const token = useToken();
  const userId = useEmployeeId();
  const userName = useUserName();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const localData = getLocalStorageData();
  const { pathname } = useLocation();
  const [loader, setloader] = useState(false);
  const [currenetTab, setcurrentTab] = useState(null);
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });

  const loginUserData = getCookie("vt_enterprise_login")
    ? getCookie("vt_enterprise_login")?.data
    : null;
  console.log(localData, "localData?.list_of_products?.menu");

  const [menuData, setmenuData] = useState([
    {
      key: "1",
      label: "List Of Products",
      naviagationPath: "/product_list",
      isVisible: localData?.list_of_products?.menu === 1,
      width: "85%",
    },
    {
      key: "2",
      label: "Inspection Criteria",
      naviagationPath: "/inspection_criteria",
      isVisible: localData?.inspection_criteria?.menu === 1,
      width: "87%",
    },

    {
      key: "3",
      label: "Prepare Inspection Report",
      naviagationPath: "/prepare_inspection_report",
      isVisible: localData?.prepar_inspection_report?.menu === 1,
      width: "90%",
    },

    {
      key: "4",
      label: "Saved Logs",
      naviagationPath: "/saved_logs",
      isVisible: localData?.saved_logs?.menu === 1,
      width: "80%",
    },
    {
      key: "5",
      label: "Export",
      naviagationPath: "/export_page",
      isVisible: localData?.export?.menu === 1,
      width: "72%",
    },
  ]);

  console.log(
    menuData.filter((ele) => ele?.isVisible),
    "menuData"
  );

  const filteredItems = [
    {
      key: "1",
      label: "Create Account",
      icon: <UserAddOutlined />,
      isVisible: localData?.emploees?.menu === 1,
    },
    {
      key: "2",
      label: "Change Password",
      icon: <LockOutlined />,
      isVisible: localData?.change_password?.change_password === 1,
    },
    {
      key: "3",
      label: "Logout",
      icon: <LogoutOutlined />,
      isVisible: true,
    },
  ];
  // const items =
  //   loginUserData?.user_type === 1
  //     ? filteredItems
  //     : loginUserData?.user_type === 2
  //     ? [...filteredItems.slice(0, 1), ...filteredItems.slice(2)]
  //     : filteredItems.slice(2);

  const items = filteredItems.filter((ele) => ele?.isVisible);
  const hamburgerData = [
    {
      id: 1,
      name: "List of Products",
      naviagationPath: "/product_list",
      icon: dash,
      isVisible: localData?.list_of_products?.menu === 1,
      privatePermission: true,
    },
    {
      id: 2,
      name: "Inspection Criteria",
      naviagationPath: "/inspection_criteria",
      icon: incoming_icon,
      isVisible: localData?.inspection_criteria?.menu === 1,
    },
    {
      id: 3,
      name: "Prepare Inspection Report",
      pathname: "prepare_inspection_report",
      naviagationPath: "/prepare_inspection_report",
      icon: prepare_report_icon,
      privatePermission: true,
      isVisible: localData?.prepar_inspection_report?.menu === 1,
    },
    {
      id: 8,
      name: "Saved Logs",
      naviagationPath: "/saved_logs",
      icon: saved_logsIcon,
      isVisible: localData?.saved_logs?.menu === 1,
    },
    {
      id: 4,
      name: "Export",
      naviagationPath: "/export_page",
      icon: export_icon,
      isVisible: localData?.export?.menu === 1,
    },
    {
      id: 5,
      name: "Create Account",
      naviagationPath: "/employee_list",
      icon: create_account,
      isVisible: localData?.emploees?.menu === 1,
    },
    {
      id: 6,
      name: "Change Password",
      naviagationPath: "/change_password",
      icon: change_password,
      isVisible: localData?.change_password?.change_password === 1,
    },
    {
      id: 7,
      name: "Logout",
      icon: logout,
      privatePermission: true,
      isVisible: true,
    },
  ];

  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow((prevShow) => !prevShow);
  };

  const handleLogout = () => {
    setloader(true);
    let formData = new FormData();
    formData.append("token", token);
    formData.append("user_id", userId);
    signOut(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          setCookie("vt_enterprise_login", null, 0);
          navigate("/");
          localStorage.removeItem(permissionKey);
          dispatch(handleStoreUserData(null));
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          getCatchMsg(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setloader(false);
      });
  };

  const handleNavigateTabs = (data) => {
    setcurrentTab(parseInt(data));
    const getPath = menuData.find(
      (ele) => parseInt(ele?.key) === parseInt(data)
    )?.naviagationPath;
    navigate(getPath);
  };

  const onClick = (value) => {
    if (parseInt(value.key) === 1) {
      navigate("/employee_list");
    } else if (parseInt(value.key) === 2) {
      navigate("/change_password");
    } else if (parseInt(value.key) === 3) {
      setdeleteModal((prev) => {
        return {
          ...prev,
          modal: true,
        };
      });
    }
  };

  useEffect(() => {
    if (pathname === "/product_list") {
      setcurrentTab("1");
    } else if (pathname === "/inspection_criteria") {
      setcurrentTab("2");
    } else if (pathname === "/prepare_inspection_report") {
      setcurrentTab("3");
    } else if (pathname === "/saved_logs") {
      setcurrentTab("4");
    } else if (pathname === "/export_page") {
      setcurrentTab("5");
    } else {
      setcurrentTab(null);
    }
  }, [pathname]);

  return (
    <>
      {loader ? <Loader /> : null}
      <GlobalModal
        CustomWidth={500}
        isOpen={deleteModal.modal}
        onCancel={() => {
          setdeleteModal((prev) => {
            return {
              ...prev,
              modal: false,
            };
          });
        }}
      >
        <LogoutConfirmationModal
          msg={"Are you sure do you want to logout?"}
          closeIcon={false}
          onClose={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
          onPositiveButtonPressed={handleLogout}
          onNegativeButtonPressed={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        />
      </GlobalModal>
      <div className={classes.container}>
        <div className={classes.menu}>
          <div onClick={toggleShow} className={classes.menuIcon}>
            <img src={menuIcon} alt="menuIcon" />
          </div>
          <div className={classes.sideMenuLogo}>
            <h5
              style={{ color: "white", marginLeft: "10px", cursor: "pointer" }}
            >
              V.T. ENTERPRISE
            </h5>
          </div>
          <div className={{ backgroundColor: "red" }}>
            <Drawer
              open={show}
              onClose={toggleShow}
              placement="left"
              closeIcon={false}
              width={270}
            >
              <div className={classes.child3}>
                <div style={{ display: "flex" }}>
                  <img
                    src={dummyIcon}
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    alt="icon"
                  />
                  <p
                    style={{
                      padding: "0 5px",
                      textAlign: "center",
                      alignSelf: "center",
                      fontFamily: "var(--fontMedium)",
                      color: "white",
                      textTransform: "capitalize",
                    }}
                  >
                    {getTrimString(userName, 15)}
                  </p>
                </div>

                <div onClick={toggleShow}>
                  <img src={CloseIcon} alt="Close icon" />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "white",
                  marginTop: "20px",
                }}
              ></div>
              <div className={classes.child2}>
                {hamburgerData.map((ele, i) => {
                  if (ele?.isVisible) {
                    return (
                      <div key={ele.id.toString()}>
                        <div
                          onClick={() => {
                            if (ele?.id === 7) {
                              setdeleteModal((prev) => {
                                return {
                                  ...prev,
                                  modal: true,
                                };
                              });
                            } else {
                              toggleShow();
                              navigate(ele?.naviagationPath);
                            }
                          }}
                        >
                          <div className={classes.sideMenu}>
                            <div className={classes.menu_content}>
                              <img
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                                src={ele?.icon}
                                alt="icons"
                              />
                              <p
                                style={{ color: "white" }}
                                onClick={() =>
                                  ele.name === "Logout" &&
                                  setdeleteModal((prev) => {
                                    return {
                                      ...prev,
                                      modal: true,
                                      id: "",
                                    };
                                  })
                                }
                              >
                                {ele.name}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              marginTop: "-10px",
                              marginBottom: "3px",
                              width: "45px",
                              marginLeft: "45px",
                              borderBottom:
                                pathname === ele.naviagationPath ||
                                (ele.id === 3 &&
                                  [
                                    "/Prepareinscepectionreport/incoming_inspection_report",
                                    "/Prepareinscepectionreport/final_inspection_report",
                                    "/Prepareinscepectionreport/setting_approval_report",
                                    "/Prepareinscepectionreport/line_inspection_report",
                                  ].includes(pathname)),
                            }}
                          ></div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </Drawer>
          </div>
        </div>
        <div className={classes.content}>
          <div
            onClick={() => navigate("/dashboard")}
            style={{
              height: "65px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={CompanyLogo}
              alt="logo"
              style={{ width: 40, height: 40 }}
            />
            <h5
              style={{
                marginLeft: "10px",
                cursor: "pointer",
                color: "white",
              }}
            >
              V.T. ENTERPRISE
            </h5>
          </div>
          <div className={classes.child2}>
            {/* <Tabs
              items={menuData}
              activeKey={
                currenetTab
                  ? currenetTab
                  : pathname === "/export_page/view_reports"
                  ? "5"
                  : ""
              }
              onChange={(value) => {
                console.log(value, "caffds");
                handleNavigateTabs(value);
                setcurrentTab(parseFloat(value));
              }}
              tabBarStyle={{ marginBottom: 0 }}
            /> */}
            {menuData.map((menu, index) => {
              // if (menu?.isVisible) {
              return (
                <div
                  style={{
                    padding: "0 10px",
                    display: menu?.isVisible ? "block" : "none",
                  }}
                  key={index.toString()}
                >
                  <p
                    onClick={() => {
                      handleNavigateTabs(menu.key);
                      setcurrentTab(parseFloat(menu.key));
                    }}
                    style={{
                      color: "whitesmoke",
                      padding: "10px 10px 5px 10px",
                      fontFamily: "var(--fontRegular)",
                      fontSize: "var(--textSm1)",
                      fontWeight: "var(--fontweight)",
                      cursor: "pointer",
                    }}
                  >
                    {menu.label}
                  </p>
                  {pathname.includes(menu?.naviagationPath) ? (
                    <div
                      style={{
                        backgroundColor: "whitesmoke",
                        height: "2px",
                        width: menu?.width,
                        marginLeft: "10px",
                      }}
                    />
                  ) : null}
                </div>
              );
              // }
            })}
          </div>
          <div>
            <div>
              <div
                className={classes.tabContent}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "65px",
                  cursor: "pointer",
                }}
              >
                <div className={classes.nameContainer}>
                  <Dropdown menu={{ items, onClick }} trigger={["click"]}>
                    <a>
                      <img
                        src={dummyIcon}
                        alt="Profile icon"
                        className={classes.dummyImage}
                      />

                      <Space
                        className="headerUsername"
                        style={{
                          color: "white",
                          padding: "0 8px",
                          fontFamily: "var(--fontMedium)",
                        }}
                      >
                        {getTrimString(userName, 10)}
                        <CaretDownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
