import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageHeader from "../ManagementLayoutHeader/PageHeader";
import classes from "./Management.module.css";
import {
  CustomButton,
  CustomToolTip,
  GlobalModal,
  Loader,
  TextInputBox,
} from "../../Components";
import AddInspectionCriteria from "../../Modals/AddInspectionCriteria";
import EditIcon from "../../Assets/Icons/SvgIcons/edit.svg";
import NoDataFound from "../../Components/NoDataFound";
import CustomPagination from "../../Components/CustomPagination";
import { useEmployeeId, useToken } from "../../Utility/StoreData";
import {
  criteriaListService,
  deleteCriteria,
  deleteProcess,
  listOfProcess,
} from "../../Services/Services";
import { getCatchMsg, getLocalStorageData } from "../../Utility/GeneralUtils";
import { getTableSNO } from "../../Utility/Constants";
import EditProcess from "../../Modals/EditProcess";
import DeleteIcon from "../../Assets/Icons/SvgIcons/delete.svg";
import LogoutConfirmationModal from "../../Modals/LogoutConfirmationModal";

function InspectionCriteria() {
  const token = useToken();
  const userId = useEmployeeId();
  const localData = getLocalStorageData()?.inspection_criteria;
  const [page, setPage] = useState(0);
  const [actionStatus, setactionStatus] = useState(null);
  const [loader, setloader] = useState(false);
  const [processList, setProcessList] = useState();
  const [tableListStatus, settableListStatus] = useState(false);
  const [modalProcessStatus, setmodalProcessStatus] = useState({
    data: null,
    status: false,
  });
  const [listInSpectionCriteria, setlistInSpectionCriteria] = useState();
  const [isShowModal, setIsShowModal] = useState({
    status: false,
    data: null,
    editStatus: false,
  });

  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });

  const [deleteModalProcess, setdeleteModalProcess] = useState({
    modal: false,
    data: null,
  });

  const { handleSubmit, handleChange, values, errors, touched, resetForm } =
    useFormik({
      initialValues: {
        process: "",
        part_no: "",
        activeItem: "",
      },
      validationSchema: Yup.object({
        part_no: tableListStatus
          ? Yup.string()
          : Yup.string().required("Part number is required").strict(true),
        // process: tableListStatus
        //   ? Yup.string().required("Process is required").strict(true)
        //   : Yup.string(),
      }),
      onSubmit: () => {
        if (tableListStatus && !actionStatus) {
          handleGetProcess(1, values);
        } else {
          if (actionStatus) {
            if (values?.process.trim() !== "") {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: true,
                  data: values,
                  editStatus: false,
                };
              });
            } else {
              toast.error("Process is required.");
            }
          } else {
            handleListCriteriaService(1, values);
          }
        }
      },
    });

  useEffect(() => {
    if (tableListStatus) {
      handleGetProcess();
      setPage(0);
      resetForm();
      setactionStatus(false);
    }
  }, [tableListStatus]);

  const handleListCriteriaService = (page = 1, data) => {
    setloader(true);
    let formData = new FormData();
    formData.append("token", token);
    formData.append("user_id", userId);
    formData.append("limit", 10);
    formData.append("part_no", data?.part_no);
    formData.append("process", data?.process);
    criteriaListService(page, formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          setPage(parseInt(response?.data?.data?.page) - 1);
          setlistInSpectionCriteria(response?.data?.data);
        } else if (response?.data?.status === 0) {
          setlistInSpectionCriteria(null);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setloader(false);
      });
  };

  const handleGetProcess = (page = 1, data) => {
    setloader(true);
    let formData = new FormData();
    formData.append("page", page);
    formData.append("token", token);
    formData.append("user_id", userId);
    formData.append("limit", 10);
    if (data?.process) {
      formData.append("process", data?.process);
    }
    if (data?.part_no) {
      formData.append("part_no", data?.part_no);
    }
    listOfProcess(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          setPage(parseInt(response?.data?.data?.page) - 1);
          setProcessList(response?.data?.data);
        } else if (response?.data?.status === 0) {
          setProcessList(null);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setloader(false);
      });
  };

  const handleDeleteProcess = () => {
    setloader(true);
    let formData = new FormData();
    const temp = deleteModalProcess?.data;
    formData.append("token", token);
    formData.append("part_no", temp?.part_no);
    formData.append("process_id", temp?.id);
    deleteProcess(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          const pag = processList?.items.length === 1 ? page : page + 1;
          handleGetProcess(pag);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        handleDeleteModalProcess();
        setloader(false);
      });
  };

  const handleDeleteCriteria = () => {
    setloader(true);
    let formData = new FormData();
    const temp = deleteModal?.id;
    formData.append("token", token);
    formData.append("part_no", temp?.part_no);
    formData.append("process_id", temp?.process_id);
    formData.append("process_detail_id", temp?.id);
    deleteCriteria(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          const pag =
            listInSpectionCriteria?.items.length === 1 ? page : page + 1;
          handleListCriteriaService(pag, values);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        handleDeleteModal();
        setloader(false);
      });
  };

  const handleDeleteModal = () => {
    setdeleteModal((prev) => {
      return {
        ...prev,
        modal: false,
        id: "",
      };
    });
  };

  const handleDeleteModalProcess = () => {
    setdeleteModalProcess((prev) => {
      return {
        ...prev,
        modal: false,
        data: null,
      };
    });
  };

  const getCriteriaActionStatus = () => {
    return localData?.edit_criteria || localData?.delete_criteria;
  };

  const getProcessActionStatus = () => {
    return localData?.edit_process || localData?.delete_process;
  };
  const getCriteriaList = () => {
    return (
      <>
        <thead className={classes.NormalTable}>
          <tr>
            <th>S.No</th>
            <th>Process</th>
            <th>Characteristics</th>
            <th>Specifications</th>
            <th>Units</th>
            <th>Method Of Check</th>
            {getCriteriaActionStatus() ? <th>Action</th> : null}
          </tr>
        </thead>
        <tbody>
          {listInSpectionCriteria?.items.length > 0 ? (
            listInSpectionCriteria?.items.map((products, index) => (
              <tr key={index}>
                <td>
                  {getTableSNO(
                    parseInt(listInSpectionCriteria?.page),
                    10,
                    index
                  )}
                </td>
                <td>{products?.process}</td>
                <td>{products?.characteristics}</td>
                <td>{products?.specification}</td>
                <td>{products?.units}</td>
                <td>{products?.method_of_check}</td>
                {getCriteriaActionStatus() ? (
                  <td>
                    <div className={classes.icons}>
                      {localData?.edit_criteria ? (
                        <CustomToolTip title={"Edit"}>
                          <img
                            src={EditIcon}
                            alt="edit icon"
                            style={{ width: 20, height: 20, cursor: "pointer" }}
                            onClick={() => {
                              setIsShowModal((prev) => {
                                return {
                                  ...prev,
                                  data: products,
                                  status: true,
                                  editStatus: true,
                                };
                              });
                            }}
                          />
                        </CustomToolTip>
                      ) : null}

                      {localData?.delete_criteria ? (
                        <CustomToolTip title={"Delete"}>
                          <img
                            src={DeleteIcon}
                            alt="delete icon"
                            style={{ width: 20, height: 20, cursor: "pointer" }}
                            onClick={() => {
                              setdeleteModal((prev) => {
                                return {
                                  ...prev,
                                  id: products,
                                  modal: true,
                                };
                              });
                            }}
                          />
                        </CustomToolTip>
                      ) : null}
                    </div>
                  </td>
                ) : null}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <NoDataFound />
              </td>
            </tr>
          )}
        </tbody>
      </>
    );
  };

  const getProcessList = () => {
    return (
      <>
        <thead className={classes.NormalTable}>
          <tr>
            <th>S.No</th>
            <th>Part No</th>
            <th>Process</th>
            {getProcessActionStatus() ? <th>Action</th> : null}
          </tr>
        </thead>
        <tbody>
          {processList?.items.length > 0 ? (
            processList?.items.map((products, index) => (
              <tr key={index}>
                <td>{getTableSNO(parseInt(processList?.page), 10, index)}</td>
                <td>{products?.part_no}</td>
                <td>{products?.process}</td>
                {getProcessActionStatus() ? (
                  <td>
                    <div className={classes.icons}>
                      {localData?.edit_process ? (
                        <CustomToolTip title={"Edit"}>
                          <img
                            src={EditIcon}
                            alt="edit_icon"
                            style={{ width: 20, height: 20, cursor: "pointer" }}
                            onClick={() => {
                              setmodalProcessStatus((prev) => {
                                return {
                                  ...prev,
                                  data: products,
                                  status: true,
                                };
                              });
                            }}
                          />
                        </CustomToolTip>
                      ) : null}

                      {localData?.delete_process ? (
                        <CustomToolTip title={"Delete"}>
                          <img
                            src={DeleteIcon}
                            alt="delete icon"
                            style={{ width: 20, height: 20, cursor: "pointer" }}
                            onClick={() => {
                              setdeleteModalProcess((prev) => {
                                return {
                                  ...prev,
                                  data: products,
                                  modal: true,
                                };
                              });
                            }}
                          />
                        </CustomToolTip>
                      ) : null}
                    </div>
                  </td>
                ) : null}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>
                <NoDataFound />
              </td>
            </tr>
          )}
        </tbody>
      </>
    );
  };

  return (
    <>
      {loader ? <Loader /> : null}
      <PageHeader
        secondBtn={false}
        modal={() => {
          settableListStatus(!tableListStatus);
          resetForm();
          setPage(0);
          setlistInSpectionCriteria(null);
        }}
        heading={tableListStatus ? "Edit Process" : "Inspection Criteria"}
        Btntitle={
          localData?.view_process
            ? tableListStatus
              ? "List of Inspection Criteria"
              : "Edit Process"
            : ""
        }
      />

      <GlobalModal
        CustomWidth={500}
        isOpen={deleteModal.modal}
        onCancel={handleDeleteModal}
      >
        <LogoutConfirmationModal
          msg={"Are you sure do you want to delete this criteria?"}
          closeIcon={false}
          positiveButtonText="Delete"
          onClose={handleDeleteModal}
          onPositiveButtonPressed={handleDeleteCriteria}
          onNegativeButtonPressed={handleDeleteModal}
        />
      </GlobalModal>

      <GlobalModal
        CustomWidth={500}
        isOpen={deleteModalProcess.modal}
        onCancel={handleDeleteModalProcess}
      >
        <LogoutConfirmationModal
          msg={"Are you sure do you want to delete this process?"}
          closeIcon={false}
          positiveButtonText="Delete"
          onClose={handleDeleteModalProcess}
          onPositiveButtonPressed={handleDeleteProcess}
          onNegativeButtonPressed={handleDeleteModalProcess}
        />
      </GlobalModal>

      <GlobalModal
        title={"Edit Process"}
        isOpen={modalProcessStatus.status}
        CustomWidth={500}
        onCancel={() => {
          setmodalProcessStatus((prev) => {
            return {
              ...prev,
              status: false,
            };
          });
        }}
      >
        <EditProcess
          listApiCall={() => handleGetProcess(page + 1)}
          editData={modalProcessStatus?.data}
          modalClose={() => {
            setmodalProcessStatus((prev) => {
              return {
                ...prev,
                status: false,
              };
            });
          }}
        />
      </GlobalModal>

      <div>
        <GlobalModal
          title={`${
            isShowModal?.editStatus ? "Edit" : "Add"
          } Insepction Criteria`}
          isOpen={isShowModal.status}
          onCancel={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: false,
              };
            });
          }}
        >
          <AddInspectionCriteria
            listApiCall={() => handleListCriteriaService(page + 1, values)}
            editData={isShowModal?.data}
            editStatus={isShowModal?.editStatus}
            modalClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
        <div className={classes.insepectionCreteria}>
          {tableListStatus ? (
            <div className="row">
              <div className={`col-lg-3 col-md-6 ${classes.savedLogsProcess}`}>
                <TextInputBox
                  title="Process"
                  placeHolder="Enter process"
                  value={values.process}
                  onChangeText={handleChange("process")}
                  name="process"
                  customInputProps={{
                    maxLength: 50,
                  }}
                  type={"text"}
                  errorText={
                    touched.process && errors.process ? errors.process : ""
                  }
                />
              </div>
              <div className={`col-lg-3 col-md-6 ${classes.savedLogsProcess}`}>
                <TextInputBox
                  title="Part No"
                  placeHolder="Enter part no"
                  value={values.part_no}
                  onChangeText={handleChange("part_no")}
                  name="part_no"
                  customInputProps={{
                    maxLength: 50,
                  }}
                  type={"text"}
                />
              </div>
              <div className="col-lg-2 col-xl-1 col-md-3 col-4 mt-4">
                <CustomButton
                  title="Search"
                  onButtonPress={() => {
                    if (values?.part_no || values?.process) {
                      handleSubmit();
                    }
                  }}
                  customButtonStyle={{ backgroundColor: "rgba(0,0,0,0.6)" }}
                />
              </div>
              <div className="col-lg-2 col-xl-1 col-md-3 col-4 mt-4">
                <CustomButton
                  title="Reset"
                  onButtonPress={() => {
                    if (values?.part_no || values?.process) {
                      handleGetProcess(1);
                      resetForm();
                    }
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <TextInputBox
                  title="Part Number"
                  placeHolder="Enter part number"
                  value={values.part_no}
                  onChangeText={handleChange("part_no")}
                  name="part_no"
                  customInputProps={{
                    maxLength: 50,
                  }}
                  type={"text"}
                  requiredText="*"
                  errorText={
                    touched.part_no && errors.part_no ? errors.part_no : ""
                  }
                />
              </div>
              <div className={`col-lg-3 col-md-6 ${classes.savedLogsProcess}`}>
                <TextInputBox
                  title="Process"
                  placeHolder="Enter process"
                  value={values.process}
                  onChangeText={handleChange("process")}
                  name="process"
                  customInputProps={{
                    maxLength: 50,
                  }}
                  type={"text"}
                  errorText={
                    touched.process && errors.process ? errors.process : ""
                  }
                />
              </div>
              {localData?.add ? (
                <div className="col-lg-2 col-xl-1 col-md-3 col-4 mt-4">
                  <CustomButton
                    title="Add"
                    onButtonPress={() => {
                      setactionStatus(true);
                      handleSubmit();
                    }}
                  />
                </div>
              ) : null}
              <div className="col-lg-2 col-xl-1 col-md-3 col-4 mt-4">
                <CustomButton
                  title="Search "
                  onButtonPress={() => {
                    setactionStatus(false);
                    handleSubmit();
                  }}
                  customButtonStyle={{ backgroundColor: "rgba(0,0,0,0.6)" }}
                />
              </div>
            </div>
          )}
          <div style={{ margin: "20px 0" }}>
            <div className={`table-responsive ${classes.Dashboard}`}>
              <table className={classes.listOfTable}>
                {tableListStatus ? getProcessList() : getCriteriaList()}
              </table>
            </div>
          </div>
        </div>
      </div>

      {tableListStatus
        ? processList?.totalPage > 1 && (
            <CustomPagination
              pageCount={processList?.totalPage}
              currentpage={page}
              forcePage={page}
              onPageChange={(val) => {
                handleGetProcess(val + 1);
              }}
            />
          )
        : listInSpectionCriteria?.totalPage > 1 && (
            <CustomPagination
              pageCount={listInSpectionCriteria?.totalPage}
              currentpage={page}
              forcePage={page}
              onPageChange={(val) => {
                handleListCriteriaService(val + 1, values);
              }}
            />
          )}
    </>
  );
}

export default InspectionCriteria;
