import React, { useEffect, useState } from "react";
import PageHeader from "../ManagementLayoutHeader/PageHeader";
import classes from "./Management.module.css";
import EditIcon from "../../Assets/Icons/SvgIcons/edit.svg";
import NoDataFound from "../../Components/NoDataFound";
import CustomPagination from "../../Components/CustomPagination";
import AddProducts from "../../Modals/AddProducts";
import CustomToolTip from "../../Components/CustomToolTip";
import { GlobalModal, Loader } from "../../Components";
import { deleteListOfProduct, productsList } from "../../Services/Services";
import { getCatchMsg, getLocalStorageData } from "../../Utility/GeneralUtils";
import { getTableSNO } from "../../Utility/Constants";
import { useEmployeeId, useToken } from "../../Utility/StoreData";
import DeleteIcon from "../../Assets/Icons/SvgIcons/delete.svg";
import toast from "react-hot-toast";
import LogoutConfirmationModal from "../../Modals/LogoutConfirmationModal";

function ListOfProducts() {
  const token = useToken();
  const localData = getLocalStorageData()?.list_of_products;
  const userId = useEmployeeId();
  const [page, setPage] = useState(0);
  const [loader, setloader] = useState(false);
  const [listOfProducts, setListOfProducts] = useState();
  const [isShowModal, setIsShowModal] = useState({
    status: false,
    data: null,
  });

  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    data: "",
  });

  useEffect(() => {
    if (token) handleGetProductsList();
  }, [token]);

  const handleGetProductsList = (page = 1, limit = 10) => {
    setloader(true);
    const formData = new FormData();
    formData.append("token", token);
    formData.append("user_id", userId);
    formData.append("limit", limit);
    productsList(page, formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          setPage(parseInt(response?.data?.data?.page) - 1);
          setListOfProducts(response?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setloader(false);
      });
  };

  const handleDeleteProduct = () => {
    setloader(true);
    let formData = new FormData();
    const temp = deleteModal?.data;
    formData.append("token", token);
    formData.append("part_no", temp?.part_no);
    formData.append("id", temp?.id);
    deleteListOfProduct(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          handleGetProductsList(page);
        } else if (response?.data?.status === 0) {
          toast.success(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        handleDeleteModal();
        setloader(false);
      });
  };

  const handleDeleteModal = () => {
    setdeleteModal((prev) => {
      return {
        ...prev,
        modal: false,
        data: "",
      };
    });
  };

  const getActionStatus = () => {
    return localData?.edit || localData?.delete;
  };

  return (
    <>
      {loader ? <Loader /> : null}
      <PageHeader
        secondBtn={false}
        Btntitle={localData?.add ? "Add Product" : ""}
        modal={() => {
          setIsShowModal((prev) => {
            return {
              ...prev,
              status: true,
              data: null,
            };
          });
        }}
        heading={"List Of Products"}
      />
      <GlobalModal
        title={`${isShowModal.data ? "Edit" : "Add"} Product`}
        isOpen={isShowModal.status}
        onCancel={() => {
          setIsShowModal((prev) => {
            return {
              ...prev,
              status: false,
            };
          });
        }}
      >
        <AddProducts
          listApiCall={() => handleGetProductsList(page + 1, 10)}
          editData={isShowModal?.data}
          modalClose={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: false,
              };
            });
          }}
        />
      </GlobalModal>

      <GlobalModal
        CustomWidth={500}
        isOpen={deleteModal.modal}
        onCancel={handleDeleteModal}
      >
        <LogoutConfirmationModal
          msg={"Are you sure do you want to delete this product?"}
          closeIcon={false}
          positiveButtonText="Delete"
          onClose={handleDeleteModal}
          onPositiveButtonPressed={handleDeleteProduct}
          onNegativeButtonPressed={handleDeleteModal}
        />
      </GlobalModal>
      <div className={classes.insepectionCreteria}>
        <div className={`table-responsive ${classes.Dashboard}`}>
          <table className={classes.listOfTable}>
            <thead className={classes.NormalTable}>
              <tr>
                <th>S.No</th>
                <th>Part No</th>
                <th>Part Name</th>
                <th>Customer</th>
                <th>Drawing Issue No</th>
                <th>Customer Part No</th>
                {getActionStatus() ? <th>Action</th> : null}
              </tr>
            </thead>
            <tbody>
              {listOfProducts?.items.length > 0 ? (
                listOfProducts?.items.map((products, index) => (
                  <tr key={index}>
                    <td>
                      {getTableSNO(parseInt(listOfProducts?.page), 10, index)}
                    </td>
                    <td>{products?.part_no}</td>
                    <td>{products?.part_name ? products?.part_name : "-"}</td>
                    <td>{products?.customer ? products?.customer : "-"}</td>
                    <td>
                      {products?.drawing_issue_no
                        ? products?.drawing_issue_no
                        : "-"}
                    </td>
                    <td>
                      {products?.customer_part_no
                        ? products?.customer_part_no
                        : "-"}
                    </td>
                    {getActionStatus() ? (
                      <td>
                        <div className={classes.icons}>
                          {localData?.edit ? (
                            <CustomToolTip title="Edit">
                              <img
                                src={EditIcon}
                                alt="edit_icon"
                                onClick={() => {
                                  setIsShowModal((prev) => {
                                    return {
                                      ...prev,
                                      data: products,
                                      status: true,
                                    };
                                  });
                                }}
                              />
                            </CustomToolTip>
                          ) : null}
                          {localData?.delete ? (
                            <CustomToolTip title={"Delete"}>
                              <img
                                src={DeleteIcon}
                                alt="delete icon"
                                style={{
                                  width: 20,
                                  height: 20,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setdeleteModal((prev) => {
                                    return {
                                      ...prev,
                                      data: products,
                                      modal: true,
                                    };
                                  });
                                }}
                              />
                            </CustomToolTip>
                          ) : null}
                        </div>
                      </td>
                    ) : null}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <NoDataFound />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {listOfProducts?.totalPage > 1 && (
        <CustomPagination
          pageCount={listOfProducts?.totalPage}
          currentpage={page}
          forcePage={page}
          onPageChange={(val) => {
            handleGetProductsList(val + 1, 10);
          }}
        />
      )}
    </>
  );
}

export default ListOfProducts;
