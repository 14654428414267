// Local

// export const baseUrl = "http://192.168.0.130/vtenterprise/webservice/";
// export const DOWNLOAD_URL = "http://192.168.0.130/vtenterprise/uploads/";

// test live

// export const baseUrl = "https://test.vteqpa.com/webservice/";
// export const DOWNLOAD_URL = "https://test.vteqpa.com/uploads/";

// Live;

export const baseUrl = "https://backendapi.vteqpa.com/webservice/";
export const DOWNLOAD_URL = "https://backendapi.vteqpa.com/uploads/";
