import React from "react";
import classes from "./CustomStyle.module.css";

export default function CustomCheckBox({
  customInputStyle,
  customTitleStyle,
  isChecked,
  onChangeCheckBox,
  title,
}) {
  return (
    <div className={classes.checkBoxContainer} onClick={onChangeCheckBox}>
      <input type="checkbox" checked={isChecked} style={customInputStyle} />
      {title ? <p style={customTitleStyle}>{title}</p> : null}
    </div>
  );
}
