import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  access: {},
  screens: [
    {
      id: 1,
      screenName: "List of Products",
      screenKey: "list_of_products",
      permissions: [
        {
          id: 1,
          name: "Add",
          key: "add",
          isVisible: false,
        },
        {
          id: 2,
          name: "Edit",
          key: "edit",
          isVisible: false,
        },
        {
          id: 3,
          name: "Delete",
          key: "delete",
          isVisible: false,
        },
      ],
    },
    {
      id: 2,
      screenName: "Inspection Criteria",
      screenKey: "inspection_criteria",
      permissions: [
        {
          id: 1,
          name: "Add",
          key: "add",
          isVisible: false,
        },
        {
          id: 2,
          name: "Edit Criteria",
          key: "edit_criteria",
          isVisible: false,
        },
        {
          id: 3,
          name: "Delete Criteria",
          key: "delete_criteria",
          isVisible: false,
        },
        {
          id: 4,
          name: "View Process",
          key: "view_process",
          isVisible: false,
        },
        {
          id: 5,
          name: "Edit Process",
          key: "edit_process",
          isVisible: false,
        },
        {
          id: 6,
          name: "Delete Process",
          key: "delete_process",
          isVisible: false,
        },
      ],
    },
    {
      id: 3,
      screenName: "Prepare Inspection Report",
      screenKey: "prepar_inspection_report",
      permissions: [
        {
          id: 1,
          name: "Add",
          key: "add",
          isVisible: false,
        },
        {
          id: 2,
          name: "Edit",
          key: "edit",
          isVisible: false,
        },
      ],
    },
    {
      id: 4,
      screenName: "Saved Logs",
      screenKey: "saved_logs",
      permissions: [
        {
          id: 1,
          name: "Edit",
          key: "edit",
          isVisible: false,
        },
        {
          id: 2,
          name: "Delete",
          key: "delete",
          isVisible: false,
        },
      ],
    },
    {
      id: 5,
      screenName: "Export",
      screenKey: "export",
      permissions: [
        {
          id: 1,
          name: "View",
          key: "view",
          isVisible: false,
        },
        {
          id: 2,
          name: "Export",
          key: "export",
          isVisible: false,
        },
      ],
    },
    {
      id: 6,
      screenName: "Employee List",
      screenKey: "emploees",
      permissions: [
        {
          id: 1,
          name: "Add",
          key: "add",
          isVisible: false,
        },
        {
          id: 2,
          name: "Change Password",
          key: "change_password",
          isVisible: false,
        },
        {
          id: 3,
          name: "Access",
          key: "access",
          isVisible: false,
        },
        {
          id: 4,
          name: "Delete",
          key: "delete",
          isVisible: false,
        },
      ],
    },
    {
      id: 7,
      screenName: "Change Password",
      screenKey: "change_password",
      permissions: [
        {
          id: 1,
          name: "Change Password",
          key: "change_password",
          isVisible: false,
        },
      ],
    },
  ],
};

const { actions, reducer } = createSlice({
  name: "AccessReducer",
  initialState: initialState,
  reducers: {
    handleStoreAccess: (state, action) => {
      const accessScreenKeys = Object.keys(action.payload);
      const accessData = action.payload;
      return {
        ...state,
        access: accessData,
        screens: state?.screens.map((screen) => {
          if (accessScreenKeys.includes(screen.screenKey)) {
            const updatedPermissions = screen.permissions.map((permission) => {
              return {
                ...permission,
                isVisible: accessData[screen.screenKey][permission.key] === 1,
              };
            });
            return {
              ...screen,
              permissions: updatedPermissions,
            };
          }
          return screen;
        }),
      };
    },
    handleActionSingleScreen: (state, action) => {
      const { ID, ACTION } = action.payload;
      const tempScreens = [...state?.screens];
      const ind = state?.screens?.findIndex((ele) => ele?.id === ID);
      const tempAccess = tempScreens[ind]?.permissions.map((ele) => {
        return {
          ...ele,
          isVisible: ACTION === "Add",
        };
      });
      tempScreens[ind] = { ...tempScreens[ind], permissions: tempAccess };

      state.screens = tempScreens;
    },
    handleActionSingleAccess: (state, action) => {
      const { ROWID, ID } = action.payload;
      const tempScreens = [...state?.screens];
      const ind = state?.screens?.findIndex((ele) => ele?.id === ROWID);
      const tempAccess = tempScreens[ind]?.permissions.map((ele) => {
        return {
          ...ele,
          isVisible: ele?.id === ID ? !ele?.isVisible : ele?.isVisible,
        };
      });
      tempScreens[ind] = { ...tempScreens[ind], permissions: tempAccess };
      state.screens = tempScreens;
    },
    cleanAllAccessReducer: () => {
      return initialState;
    },
  },
});

export const {
  handleStoreAccess,
  handleActionSingleScreen,
  handleActionSingleAccess,
  cleanAllAccessReducer,
} = actions;

export default reducer;
